<template>
  <svg
    role="img"
    aria-label="github"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <path
      d="M132.919,490H98.782c-17.284,0-31-14-31-31.338V298.649h31v160.013l34.137-0.007V490z"
    />
    <path
      d="M333.502,265.681c-45.66,27.738-108.09,27.737-153.748,0c-14.107,17.13-22.58,39.074-22.58,62.997v160.898h44.494 v-91.977h31v91.977h44.99v-91.977h31.345v91.977h46.733V328.678C356.082,304.754,347.609,282.81,333.502,265.681z"
    />
    <path
      d="M343.288,84.949c4.702-24.106-4.509-47.867-23.751-62.949c-13.162,9.003-26.134,22.219-25.784,39.211 c-24.542-7.568-46.306-7.51-70.708,0.045c-2.38-15.197-15.015-36.567-30.725-38.398c-16.021,15.138-23.078,37.483-18.657,59.076 l0,0c-22.22,17.351-36.028,41.607-36.028,68.455c0,52.747,53.274,95.506,118.992,95.506s118.992-42.759,118.992-95.506 C375.62,125.063,363.333,102.043,343.288,84.949z"
    />
    <rect x="397" y="137" width="48" height="31" />
    <rect
      x="75"
      y="128"
      transform="matrix(0 -1 1 0 -63 243)"
      width="31"
      height="50"
    />
  </svg>
</template>
