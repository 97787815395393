<template>
  <svg
    aria-label="email"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    version="1.1"
    viewBox="0 0 298 298"
    x="0"
    y="0"
    fill-rule="evenodd"
    clip-rule="evenodd"
  >
    <path
      transform="translate(0,80)"
      fill="currentColor"
      d="M142 84l78 -79c8,-7 19,-7 27,0l46 47c7,7 7,19 0,26l-79 79c-7,7 -19,7 -26,0l-46 -47c-8,-7 -8,-19 0,-26zm-50 -13c-5,0 -5,-8 0,-8l43 0c5,0 5,8 0,8l-43 0zm-42 -52c-5,0 -5,-7 0,-7l136 0c5,0 5,7 0,7l-136 0zm-46 26c-5,0 -5,-8 0,-8l156 0c6,0 6,8 0,8l-156 0zm145 47l79 -78c2,-3 7,-3 9,0l46 46c3,3 3,7 0,9l-78 79c-3,3 -7,3 -10,0l-46 -46c-3,-3 -3,-7 0,-10zm61 0l-10 51 -5 -1 10 -49 -50 9 -1 -5 66 -12 13 -66 5 1 -10 50 49 -10 1 5 -51 10 -3 14 -14 3zm-64 -3l79 -78c4,-5 11,-5 15,0l46 46c5,4 5,11 0,16l-78 78c-5,5 -12,5 -16,0l-46 -46c-4,-4 -4,-11 0,-16z"
    />
  </svg>
</template>
