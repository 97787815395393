
import { defineComponent, ref } from "vue";
import "css-doodle";
import { useDoodle } from "@/hook/useDoodle";
import { useScreenQuery } from "@/hook/useScreenQuery";
import { useDarkTheme } from "@/hook/useDarkTheme";
import Contact from "@/component/Contact.vue";
import IconCircle from "@/component/icon/Circle.vue";

export default defineComponent({
  components: {
    Contact,
    IconCircle,
  },
  setup() {
    const content = ref(document.createElement("div"));
    const doodleControl = useDoodle();
    const isCircleActive = ref(false);
    const { isDark } = useDarkTheme();

    if (isDark.value) {
      const doodle = document.querySelector("css-doodle") as HTMLElement;
      doodle?.style.setProperty("--background", "#001219");
    }

    const { matches: reduceMotion } = useScreenQuery(
      "(prefers-reduced-motion)"
    );

    return {
      content,
      reduceMotion,
      isCircleActive,
      ...doodleControl,
    };
  },
});
