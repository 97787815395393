import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ecc90ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "svg",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  version: "1.1",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("circle", {
      fill: "none",
      stroke: "currentColor",
      "stroke-width": "2px",
      "stroke-dasharray": "88px",
      "stroke-dashoffset": "88px",
      class: _normalizeClass(["circle", { 'circle--active': _ctx.isActive }]),
      r: "14",
      cx: "16",
      cy: "16"
    }, null, 2)
  ]))
}